import React, { useEffect } from "react"
import { motion } from 'framer-motion';
import ContentAppearance from "./content-app";
import { Box, Stack } from "@mui/material";
import "./profile.css"
import { Text } from "../../models/text";
import { useResponsive } from "../../hooks/use-responsive";
import { HEADER } from '../../layouts/dashboard/config-layout';
import { aboutMe } from "../../mock/me";
import { PhotoHoverEffect } from "../../utils/effects";
import { grey } from "../../theme/palette";
const Profile = () => {
  const lgUp = useResponsive('up', 'lg');
  const SPACE = lgUp ? 45 : 150;
  useEffect(() => {
    PhotoHoverEffect()
  })
  return (
    <Box
      id="abouts"
      style={{
        backgroundImage: `url('${""
          } ')`,
        backgroundPosition: "center",
        height: lgUp ? `${window.innerHeight - SPACE}px` : `${window.innerHeight - HEADER.H_MOBILE}px`,

      }}
      className="profile-container "
    >
      <Box
        className="profile-layout"

      >
        <Stack gap={0} className="profile-content">
          <Text variant="h3"
            styles={{
              fontWeight: "bold"
            }}
            className="profile-first-name"
          >
            <span style={{ color: "rgb(5, 175, 133)" }}>Hi,</span>   I'm

          </Text>

          <Text variant="h3"
            styles={{
              fontSize: "40px"
            }}
            className="d_content_name mobileu"
          >
            James Mensah
          </Text>

          <Text
            variant="h3"
            styles={{
              fontSize: "70px"
            }}
            id="authorname"
            className="d_content_name desktop "

          >
            James Mensah
          </Text>

          <ContentAppearance />
        </Stack>
        <motion.div
          animate={{
            scale: [0.9, 1, 0.9, 1],
            opacity: [0.8, 1, 0.9, 1],
            translate: [-50, 0],
            borderRadius: ["0%", "0%", "50%", "50%", "0%"]
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
          }}
        >
          <Box className="profile-picture desktop">
            <Box sx={(theme)=>({
              borderColor: theme.palette.mode === 'dark' ? grey[850] : theme.palette.grey[800],
            })}
              className="profile-picture_frame"

              id="pic_frame_id"
            ></Box>
            <Box
              sx={(theme) => ({
                backgroundImage: `url('${aboutMe.photo
                  }')`,
                backgroundPosition: "center",
                backgroundColor: theme.palette.mode === "dark" ?
                  "#000008" : theme.palette.grey[100],
                border: theme.palette.mode === "dark" ?
                  "" : `1px solid ${theme.palette.grey[200]}`
              })}
              className=
              "p_front"
              id="picfront"
            >
              <Box sx={{
                width: "100%",
                height: "100%",
                position: "absolute"
              }}
                className="profile-photo-cover">
              </Box>
            </Box>
          </Box> </motion.div>

      </Box>

      <div className="intro_lines">
        <Box sx={(theme) => ({
          borderBottomColor: theme.palette.mode === 'dark' ? grey[850] : theme.palette.grey[800],
          borderLeftColor: theme.palette.mode === 'dark' ? grey[850] : theme.palette.grey[800],
        })}
          className="d_introline_l introline"
        ></Box>
        <Box
        sx={(theme) => ({
          borderBottomColor: theme.palette.mode === 'dark' ? grey[850] : theme.palette.grey[800],
          borderRightColor: theme.palette.mode === 'dark' ? grey[850] : theme.palette.grey[800],
        })}
          className="d_introline_r introline"
        ></Box>
      </div>
    </Box>

  )
}


export default Profile