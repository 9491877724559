import { Box, Stack, Theme, Typography } from "@mui/material";
import React from "react";
import { BsBoxArrowUpRight, BsGithub } from "react-icons/bs";
import { ProjectsProps } from "../../mock/me";
import { ProjectContent, ProjectIcon } from "../../models/ProjectContenent";
import AnimatedContainer from "../../common-ui/GrowContainer";
import { Player } from "../../models/Player";

export const ProjectL: React.FC<ProjectsProps> = ({
  title,
  detail,
  link,
  github,
  image,
  tools,
  hasIcon,
  isVideo,
}) => {
  return (
    <Stack
      className="work"
      direction="row"
      alignItems="center"
      sx={styles.mainContainer}
    >
      <Stack
        className="project-content "
        direction="column"
        gap={3}
        sx={() => ({
          width: "45%",
          marginRight: "-60px",
          paddingTop: "10px",
        })}
      >
        <AnimatedContainer delay={300}>
          <Box sx={styles.contentLayout}>
            {hasIcon && (
              <img
                src={"assets/images/lol.gif"}
                alt="icon"
                style={styles.icons}
              />
            )}
            <Typography
              variant="h5"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "dark"
                    ? "rgb(5, 175, 133)"
                    : theme.palette.grey[700],
                fontWeight: "bold",
              })}
            >
              {title}
            </Typography>
          </Box>
        </AnimatedContainer>

        <AnimatedContainer delay={400}>
          <ProjectContent detail={detail} />
        </AnimatedContainer>

        <Stack direction="column" gap={1}>
          <AnimatedContainer delay={460}>
            <Stack direction="row" gap={1}>
              {tools &&
                tools.map((tool, index) => {
                  return (
                    <Typography
                      sx={(theme) => ({
                        color:
                          theme.palette.mode === "dark"
                            ? "#13bd93"
                            : theme.palette.grey[700],
                        fontSize: "13px",
                      })}
                      variant="subtitle2"
                      key={index}
                    >
                      {tool}
                    </Typography>
                  );
                })}
            </Stack>
          </AnimatedContainer>
        </Stack>
        <AnimatedContainer delay={550}>
          <Stack gap={1} direction="row" className="d_work_p_link">
            {link && (
              <a
                href={link}
                className="mouse_interaction-target"
                target="_blank"
                rel="noopener noreferrer"
                title={`Explore more about ${title}- ${link}`}
              >
                <Typography
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "dark"
                        ? "#13bd93"
                        : theme.palette.grey[700],
                  })}
                >
                  <BsBoxArrowUpRight />
                </Typography>
              </a>
            )}

            {github && (
              <a
                href={github}
                className="mouse_interaction-target"
                target="_blank"
                rel="noopener noreferrer"
                title={`Check out ${title}'s codebase on GitHub - ${github}`}
              >
                <Typography
                  sx={(theme) => ({
                    color:
                      theme.palette.mode === "dark"
                        ? "#13bd93"
                        : theme.palette.grey[700],
                    fontSize: "13px",
                  })}
                >
                  {" "}
                  <BsGithub color="rgb(5, 175, 133)" />
                </Typography>
              </a>
            )}
          </Stack>
        </AnimatedContainer>
      </Stack>

      {isVideo ? <Player path={image??''}/> : <ProjectIcon image={image} />}
    </Stack>
  );
};

const styles = {
  mainContainer:(theme:Theme)=>({
    [theme.breakpoints.up('xl')]: {
width:'93%'
    },
     [theme.breakpoints.down('xl')]: {
      width:'100%'
    },

  }),
  icons: {
    height: "30px",
    width: "30px",
    borderRadius: "50px",
  },
  contentLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 1,
  },
};
