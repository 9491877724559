import { Box, Typography } from "@mui/material";
export const ProjectContent = ({ detail }: { detail: string }) => {
  return (
    <Box
      className="d_work_p_c"
      sx={(theme) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1d1d26" : "",
        background:
          theme.palette.mode === "dark"
            ? ""
            : `radial-gradient(circle, rgba(205,207,219,1) 0%, rgba(183,189,247,1) 100%)`,
        minHeight: "150px",
        padding: "10px 8px",
        boxShadow:
          theme.palette.mode === "dark"
            ? ""
            : "rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px",
      })}
    >
      <Typography
        sx={(theme) => ({
          color:
            theme.palette.mode === "dark"
              ? theme.palette.grey[300]
              : theme.palette.grey[900],
          fontSize: "14px",
        })}
        variant="subtitle1"
      >
        {detail}
      </Typography>
    </Box>
  );
};

export const ProjectIcon = ({ image }: { image?: string }) => {
 
  return (
    <Box
      sx={{
        width: "55%",
        overflow: "hidden",
        borderRadius: "15px",
        boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
       }}
      className="work_imagecontainer"
    >
      <img
        className="d_work_image"
        style={{ borderRadius: "15px" }}
        src={image}
        alt=""
      ></img>
    </Box>
  );
};
