import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { mySelf } from "./../../mock/me"
import { Text } from "../../models/text";
import { TextSwipe } from "../../utils/effects";
const ContentAppearance = () => {
    useEffect(() => {
        TextSwipe()
    }, [])
    return (
        <div className="contentId_data" >
            <Text
                variant="subtitle1"
                id="authorIntro"
                className="d_content_brief"
                sx={(theme) => ({
                    color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[700]
                })}
            >
                {mySelf}
            </Text>
            <Box
                id="contentSwipe"
                className="brief_swip"
            >
                <Text variant="h3">I Build and Design </Text>
                <Box className="slidingVertical">
                    <Box id="container" className="swipe-stack-mobile">
                        <span id="text1"></span>
                        <span id="text2"></span>
                    </Box>
                    <svg id="filters">
                        <defs>
                            <filter id="threshold">
                                <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 255 -140" />
                            </filter>
                        </defs>
                    </svg>

                </Box>


            </Box>
        </div>
    );
};

export default ContentAppearance;
