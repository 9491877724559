import React from "react";
import { scrollToComponent, scrollToTop } from "../utils/effects";
import { Box, Stack, Theme } from "@mui/material";
interface NavBoxItemProps {
  onClick: () => void;
  id: string;
}

const NavBoxItem: React.FC<NavBoxItemProps> = ({ onClick, id }) => {
  return (
    <Box
      className="nav-box mouse_interaction-target"
      sx={styles.navBox}
      onClick={onClick}
      id={id}
    ></Box>
  );
};

export const NavBoxs: React.FC = () => {
  return (
    <Stack alignItems="center" spacing={3} className="d_fix_contact_line fix_contact_l">
      <NavBoxItem onClick={scrollToTop} id="about_id" />
      <NavBoxItem onClick={() => scrollToComponent("experience")} id="experience_id" />
      <NavBoxItem onClick={() => scrollToComponent("works")} id="work_id" />
      <NavBoxItem onClick={() => scrollToComponent("contactme")} id="contact_id" />
    </Stack>
  );
};

const styles={
  navBox:(theme:Theme)=>({
      padding: '3px 0',
      backgroundColor: theme.palette.mode==="dark" ?'rgb(46, 159, 131)' : theme.palette.grey[600],
      borderRadius: '5px',
      cursor: 'pointer',
      transition: '2s ease-in-out'
      ,
      width:'14px',
      height:'14px',
    
  })
}