import DashboardLayout from '../layouts/dashboard';
import { PageIamges } from '../mock/me';
import Particles from '../models/particles';
import AppView from '../sections/overview/view/app-view';
import  { Suspense } from 'react';
export type PaletteMode = 'light' | 'dark';
// ----------------------------------------------------------------------

interface HeaderProps {
  mode: PaletteMode;
  toggleColorMode: () => void;

}
export default function AppPage({ mode, toggleColorMode }: HeaderProps) {

  return (
    <>
      <title>James mensah </title>
      <DashboardLayout mode={mode} toggleColorMode={toggleColorMode} >
        <Suspense fallback={<div>Loading ...</div>}>
        <Particles className="fixed inset-0 pointer-events-none z-1" quantity={70} staticity={40} />
          <AppView />
          
          <div className='fixed top-0 right-0 -translate-y-1/3 translate-x-1/2 blur-3xl opacity-15 pointer-events-none z-0'>
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="852" height="582" alt="Illustration" />
          </div>
          <div className="fixed top-0 left-20 rotate-90 -translate-x-3/4 -scale-x-100 blur-3xl opacity-50 pointer-events-none z-0" aria-hidden="true">
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="852" height="582" alt="Illustration" />
          </div>
          <div className="fixed  rotate-180 top-40  -translate-y-3/4 -scale-x-100 blur-3xl opacity-10 pointer-events-none z-0" aria-hidden="true">
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="852" height="582" alt="Illustration" />
          </div>
          <div className="fixed  rotate-90 translate-y-1/4 translate-x-1/4 -scale-x-100 blur-3xl opacity-40 pointer-events-none z-0" aria-hidden="true">
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="202" height="202" alt="Illustration" />
          </div>
          <div className="fixed bottom-1/2 right-1/3 rotate-90 translate-y-3/4 translate-x-3/4 -scale-x-100 blur-3xl opacity-40 pointer-events-none z-0" aria-hidden="true">
            <img src={PageIamges.backgroundAbsolute} className="max-w-none" width="202" height="202" alt="Illustration" />
          </div>
        </Suspense>
      </DashboardLayout>

    </>
  );
}
