import PropTypes from 'prop-types';
import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import {HEADER } from './config-layout';

import { useResponsive } from '../../hooks/use-responsive';
import ToggleColorMode from '../../component/ToggleColorMode';
import { useTheme } from '@mui/material/styles';
import { RiMenu4Fill } from "react-icons/ri";
export type PaletteMode = 'light' | 'dark';
interface HeaderProps {

  mode: PaletteMode;
  toggleColorMode: () => void;
  HeaderComponent?: React.JSX.Element
  onOpenNav:()=>void;
}

export default function Header({ mode, toggleColorMode,onOpenNav }: HeaderProps): ReactElement {
  const lgUp = useResponsive('up', 'lg');
 
  const renderContent = (
    <>
      {!lgUp && (
         <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
         <Box><RiMenu4Fill/> </Box>
       </IconButton>
      )}
    
    


      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={1}>
      
    {
      !lgUp && <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
        
       
    }
        
      </Stack>
    </>
  );
  const theme = useTheme();
  return (
    <AppBar
      sx={{
    
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
       ...(!lgUp && {

       }),
        ...(lgUp && {
          width: `100%`,
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
