import { LabelProps } from "../utils/types";
import { Typography } from "@mui/material";
import React from "react";



export const SubText:React.FC<LabelProps>=({className,styles,children})=>{
    return (
        <Typography 

                variant="subtitle1"
        style={styles}
        className={className}
        sx={(theme)=>({
            fontWeight:600,
            color:theme.palette.mode==="dark" ? "#14f0b9" : "#34bf9d"
          })}
       
       >{children}</Typography>
    )
}


