import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useResponsive } from '../../hooks/use-responsive';
import { NAV, HEADER } from './config-layout';

interface MainProps {
  children: ReactNode;
  sx?: object;
}

const SPACING = 20;

export default function Main({ children, sx, ...other }: MainProps) {
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
       
        py: `${HEADER.H_MOBILE }px`,
        ...(lgUp && {
          px: 2,
          py: `${SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
