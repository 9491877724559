import { Stack, Box, Typography, Theme } from "@mui/material";
import { projectsList, ProjectsProps } from "../../mock/me";
import { useResponsive } from "../../hooks/use-responsive";
import "./project.css";
import { ProjectL } from "./project-left";
import { ProjectXsL } from "./project-mobile";
import { ProjectR } from "./project-right";
import AnimatedContainer from "../../common-ui/GrowContainer";
const Projects = () => {
  const upLg = useResponsive("up", "md");

  return (
    <Stack id="works" direction="column" gap={4} sx={styles.layout}>
      
          <Typography
            variant="h3"
            sx={styles.header}
            id="projectheader"
            className="projec_intro targets-container"
          >
            Projects .
          </Typography>
    

      <Stack
        gap={upLg ? 14 : 8}
        sx={styles.cardContainer}
      >
        {projectsList &&
          projectsList.map((data: ProjectsProps, index: number) => {
            return (
              <Box key={index}>
                {upLg ? (
                  index % 2 === 0 ? (
                    <AnimatedContainer>
                      <ProjectR
                        title={data.title}
                        detail={data.detail}
                        link={data.link}
                        github={data.github}
                        image={data.image}
                        tools={data.tools}
                        hasIcon={data.hasIcon}
                        isVideo={data.isVideo}
                      />
                    </AnimatedContainer>
                  ) : (
                    <AnimatedContainer>
                      <ProjectL
                        title={data.title}
                        detail={data.detail}
                        link={data.link}
                        github={data.github}
                        image={data.image}
                        tools={data.tools}
                        hasIcon={data.hasIcon}
                        isVideo={data.isVideo}
                      />
                    </AnimatedContainer>
                  )
                ) : (
                  <AnimatedContainer>
                    <ProjectXsL
                      title={data.title}
                      detail={data.detail}
                      link={data.link}
                      github={data.github}
                      image={data.image}
                      tools={data.tools}
                      hasIcon={data.hasIcon}
                      isVideo={data.isVideo}
                    />
                  </AnimatedContainer>
                )}
              </Box>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default Projects;

const styles = {
  layout: {
    margin: "60px 0px",
  },
  contents: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",
    width: "95%",
  },
  header: (theme: Theme) => ({
    color:
      theme.palette.mode === "dark"
        ? "rgb(5, 175, 133)"
        : theme.palette.grey[900],

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "530px",
    },
  }),

  cardContainer:() => ({
    width: "100%",
    minHeight: '100vh',
  })
};
