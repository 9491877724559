import { LabelProps } from "../utils/types";
import { Typography } from "@mui/material";
import React from "react";



export const Label:React.FC<LabelProps>=({className,styles,children,id})=>{
    return (
        <Typography 

                variant="subtitle1"
        style={styles}
        className={className}
        id={id}
        sx={(theme)=>({
            color:theme.palette.mode==="dark" ? theme.palette.grey[200] :theme.palette.grey[800]
        })}>{children}</Typography>
    )
}


