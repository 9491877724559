import React from "react";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { MdWhatsapp } from "react-icons/md";
import { BsTelegram } from "react-icons/bs";
import { FaGithubSquare } from "react-icons/fa";
interface contactProps {
  name: string;
  icon: React.ReactElement;
  link: string;
}
const ICON_SIZE = 23 
export const contacts: contactProps[] = [
  {
    name: "Github",
    icon: <FaGithubSquare size={ICON_SIZE} />,
    link: "https://github.com/james-mensa",
  },
  {
    name: "Linkedin",
    icon: <FaLinkedin size={ICON_SIZE} />,
    link: "https://www.linkedin.com/in/james-mensah-a8a56a266/",
  },
  {
    name: "Facebook",
    icon: <FaFacebookSquare size={ICON_SIZE} />,
    link: "https://web.facebook.com/mancoded202/",
  },

  {
    name: "Telegram",
    icon: <BsTelegram size={ICON_SIZE} />,
    link: "https://t.me/jamesmensahcoded",
  },

  {
    name: "whatsapp",
    icon: <MdWhatsapp size={ICON_SIZE} />,
    link: "https://wa.me/233557471572",
  },
];
