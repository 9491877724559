
import { SubText } from "../../models/LabelSubtext"
import { Label } from "../../models/label"
import { Box, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { scrollToComponent } from "../../utils/effects"






export const NavContactDesktop=()=>{
  useEffect(()=>{
 
  },[])
    return(
        <Stack className="nav-btns header"
        direction="row"
         alignItems="center"
         justifyContent="flex-end"
         sx={{width:"100%"
       
        }}
         gap={2}  
     
              >
             
            
                <Stack gap={0.5} className="mouse_interaction-target" direction ="row"alignItems="center" onClick={() => {scrollToComponent("expeience")}}>
                <SubText>01.</SubText>


             <Label className="desktop-nav-label">Experience</Label> 
                </Stack>
                <Stack gap={0.5}  className="mouse_interaction-target" direction ="row"alignItems="center" onClick={() => {scrollToComponent("works")}}>
              
                <SubText>02.</SubText>
              <Label className="desktop-nav-label">Projects</Label> 
                </Stack>
                <Stack gap={0.5}  className="mouse_interaction-target" direction ="row"alignItems="center" onClick={() => {scrollToComponent("contactme")}}>
               
                <SubText>03.</SubText>
               <Label className="desktop-nav-label">Contact me</Label> 
                </Stack>
                <Box
                  className=
                   "d_resumespan mouse_interaction-target"
                   sx={{ border: '2px solid rgb(20, 240, 185)'}}
                >
                  <Typography
                  variant="subtitle2"
                    sx={(theme)=>({
                      color:theme.palette.text.primary
                    })}
                    onClick={() =>
                      // Downloadlink(
                      //   `${
                      //     myid && myid.person
                      //       ? myid.person.resume
                      //       : "https://myportfoliocontent.s3.eu-north-1.amazonaws.com/Document%20/James-mensah-resume.pdf29743.120397494295"
                      //   }`
                      // )
                      {}
                    }
                  >
                    Resume
                  </Typography>
                </Box>
              </Stack>
    )
}
