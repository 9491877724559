import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import IndexPage from '../page/app';



export type PaletteMode = 'light' | 'dark';



interface HeaderProps {
  onOpenNav?: () => void;
  mode: PaletteMode;
  toggleColorMode: () => void;
}




export default function AppRouter({ mode, toggleColorMode }: HeaderProps) {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={

         
              <IndexPage mode={mode} toggleColorMode={toggleColorMode} />
      
          }
        />
       
        <Route path="/404" element={<p>ss</p>} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Router>
  );
}

