import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import { BsFillMoonStarsFill } from "react-icons/bs";
export type PaletteMode = 'light' | 'dark';
export interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

const ToggleColorMode = ({ mode, toggleColorMode }: AppAppBarProps) => {
  return (
    <Box sx={{ maxWidth: '32px', marginBottom: "8px" }}>
      <Button className='mouse_interaction-target'
        variant="text"
        onClick={toggleColorMode}
        size="small"
        aria-label="button to toggle theme"
        sx={{ minWidth: '32px', height: '32px', p: '4px' }}
      >
        {mode === 'dark' ? (
          <WbSunnyRoundedIcon fontSize="small" 
          />
        ) : (
          <BsFillMoonStarsFill fontSize="medium" color='#01021a' />
        )}
      </Button>
    </Box>
  );
}

ToggleColorMode.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default ToggleColorMode;
