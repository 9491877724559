import { Box, Grid, Stack, Theme, Typography } from "@mui/material";
import React from "react";
import { BsBoxArrowUpRight, BsGithub } from "react-icons/bs";
import { ProjectsProps } from "../../mock/me";
import AnimatedContainer from "../../common-ui/GrowContainer";
import { Player } from "../../models/Player";

export const ProjectXsL: React.FC<ProjectsProps> = ({
  title,
  detail,
  link,
  github,
  image,
  tools,
  hasIcon,
  isVideo
}) => {
  return (
    <Box sx={styles.container}>
    {
      isVideo ?

      <Player path={image!}/>:<img
      alt=""
      className="d_work_image"
      style={styles.image}
      src={image}
    ></img>
    }
    
      <Stack direction="column" gap={2} sx={styles.content}>
        <AnimatedContainer delay={300}>
        <Box sx={styles.contentLayout}>
     
          <Typography
            variant="h5"
            sx={(theme) => ({
              color:
                theme.palette.mode === "dark"
                  ? "rgb(5, 175, 133)"
                  : theme.palette.grey[700],
              fontWeight: "bold",
            })}
          >
            {title}
          </Typography>
          {hasIcon && (
            <img
              src={"assets/images/lol.gif"}
              alt="icon"
              style={styles.icons}
            />
          )}
        </Box>
        </AnimatedContainer>
        <AnimatedContainer delay={400}>
          <Box sx={styles.contentDetail}>
            <Typography
              sx={(theme) => ({
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[300]
                    : theme.palette.grey[100],
                fontSize: "8px",
              })}
              variant="body2"
            >
              {detail}
            </Typography>
          </Box>
        </AnimatedContainer>

        <AnimatedContainer delay={500}>
          <Stack direction="column" gap={1}>
            <Grid container direction="row" spacing={1}>
              {tools &&
                tools.map((tool, index) => (
                  <Grid item key={index} xs={3}>
                    <Typography variant="body2" sx={{ fontSize: "9px" }}>
                      {tool}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </AnimatedContainer>
        <AnimatedContainer delay={650}>
          <Stack gap={1} direction="row" className="d_work_p_link">
            {link && (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                title={`Explore more about ${title}- ${link}`}
              >
                <BsBoxArrowUpRight color="rgb(5, 175, 133)" />
              </a>
            )}

            {github && github && (
              <a
                href={github}
                target="_blank"
                rel="noopener noreferrer"
                title={`Check out ${title}'s codebase on GitHub - ${github}`}
              >
                <BsGithub color="rgb(5, 175, 133)" />
              </a>
            )}
          </Stack>
        </AnimatedContainer>
      </Stack>
    </Box>
  );
};

const styles = {
  container: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      width: "95%",
      marginBottom: "15px"     
    },
    [theme.breakpoints.up('sm')]: {
      width: "70%",
    },
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  image: {
    height: "160px",
    borderRadius: "10px",
    width: "95%",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  contentDetail: (theme: Theme) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[600]
        : "rgb(5, 175, 133)",

    padding: "5px",
  }),
  content: (theme: Theme) => ({
    width: "100%",
    padding: "10px",
    borderRadius: "10px",
    overflow: "hidden",
    
  }),
  contentLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 1,
  },
  icons: {
    height: "17px",
    width: "17px",
    borderRadius: "50px",
  },
};
