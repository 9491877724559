import { animate, inView } from "framer-motion";

export const Shownav = (div: string) => {
  const containers = document.querySelectorAll(div);

  if (!containers) return;
  containers.forEach((container) => {
    let condition: boolean;
    window.addEventListener("scroll", function () {
      let scrollHeight = window.scrollY;

      if (scrollHeight > 4) {
        if (container.classList) {
          condition = container.classList.contains("hidetabs");
        }

        if (!condition) {
          container.classList.add("hidetabs");
        }
      }

      if (scrollHeight < 4) {
        if (condition) {
          container.classList.remove("hidetabs");
        }
      }
    });
  });
};

export const Showavater = (div: string) => {
  const container = document.getElementById(div);
  let condition: boolean = false;
  window.addEventListener("scroll", function () {
    let scrollHeight = window.scrollY;

    if (scrollHeight > 550) {
      if (container && container.classList) {
        condition = container.classList.contains("showprofile");
      }

      if (!condition && container) {
        container.classList.add("showprofile");
      }
    } else if (scrollHeight < 550) {
      if (condition === true) {
        container && container.classList.remove("showprofile");
      }
    }
  });
};

export const ShowNavs = () => {
  const Contenttab = document.querySelectorAll(".fix_contact_l");
  window.addEventListener("scroll", () => {
    try {
      let topheight = document.documentElement.scrollHeight;

      if (topheight > window.innerHeight) {
        Contenttab.forEach((div) => {
          if (div.classList.contains("Contenttab_show")) {
            div.classList.remove("Contenttab_show");
          }
        });
      } else {
        Contenttab.forEach((div) => {
          if (!div.classList.contains("Contenttab_show")) {
            div.classList.add("Contenttab_show");
          }
        });
      }
    } catch (error) {
      throw error;
    }
  });
};

export const TextSwipe = () => {
  const elts: any = {
    text1: document.getElementById("text1"),
    text2: document.getElementById("text2"),
  };

  const texts = ["Mobile Applications.", "Web Platforms.","Web3 Solutions."];

  const morphTime = 1.5;
  const cooldownTime = 2;

  let textIndex = texts.length - 1;
  let time: any = new Date();
  let morph = 0;
  let cooldown = cooldownTime;

  elts.text1.textContent = texts[textIndex % texts.length];
  elts.text2.textContent = texts[(textIndex + 1) % texts.length];

  function doMorph() {
    morph -= cooldown;
    cooldown = 0;

    let fraction = morph / morphTime;

    if (fraction > 1) {
      cooldown = cooldownTime;
      fraction = 1;
    }

    setMorph(fraction);
  }

  function setMorph(fraction: number) {
    elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

    fraction = 1 - fraction;
    elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

    elts.text1.textContent = texts[textIndex % texts.length];
    elts.text2.textContent = texts[(textIndex + 1) % texts.length];
  }

  function doCooldown() {
    morph = 0;

    elts.text2.style.filter = "";
    elts.text2.style.opacity = "100%";

    elts.text1.style.filter = "";
    elts.text1.style.opacity = "0%";
  }

  function animate() {
    requestAnimationFrame(animate);

    let newTime: any = new Date();
    let shouldIncrementIndex = cooldown > 0;
    let dt = (newTime - time) / 1000;
    time = newTime;

    cooldown -= dt;

    if (cooldown <= 0) {
      if (shouldIncrementIndex) {
        textIndex++;
      }

      doMorph();
    } else {
      doCooldown();
    }
  }

  animate();
};

export const scrollToComponent = (div: string) => {
  const container = document.getElementById(div);
  container && container.scrollIntoView({ behavior: "smooth" });
};
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
export const componentonMount = () => {
  const elements = document.querySelectorAll(".targets-container");
  const targetsWidth = document.querySelectorAll(".targets-container-width");
  const experienceHeader = document.getElementById("projectheader");
  const leftNav = document.querySelector(".fix_contact_l");
  const leftContainer = document.querySelector(".left-nav-links");
  const tab1 = document.getElementById("about_id");
  const tab2 = document.getElementById("experience_id");
  const tab3 = document.getElementById("work_id");
  const tab4 = document.getElementById("contact_id");
  const about = document.getElementById("abouts");
  const experience = document.getElementById("expeience");
  const works = document.getElementById("works");
  const contactme = document.getElementById("contactme");

  window.addEventListener("scroll", () => {
    var atop = about ? about.getBoundingClientRect().top : 0;
    var abottom = about ? about.getBoundingClientRect().bottom : 0;
    var etop = experience ? experience.getBoundingClientRect().top : 0;
    var ebottom = experience ? experience.getBoundingClientRect().bottom : 0;
    var wtop = works ? works.getBoundingClientRect().top : 0;
    var wbottom = works ? works.getBoundingClientRect().bottom : 0;
    var ctop = contactme ? contactme.getBoundingClientRect().top : 0;
    var cbottom = contactme ? contactme.getBoundingClientRect().bottom : 0;

    if (atop < window.innerHeight / 2 && abottom > window.innerHeight / 5) {
      tab1 && tab1.classList.add("spintab");
    } else {
      tab1 && tab1.classList.remove("spintab");
      leftContainer?.classList.remove("left-nav-links");
      leftContainer?.classList.add("initial_nav_link");
    }

    if (etop < window.innerHeight / 2 && ebottom > window.innerHeight / 5) {
      tab2?.classList.add("spintab");
    } else {
      tab2 && tab2.classList.remove("spintab");
    }

    if (wtop < window.innerHeight / 2 && wbottom > window.innerHeight / 5) {
      tab3 && tab3.classList.add("spintab");
    } else {
      tab3 && tab3.classList.remove("spintab");
    }

    if (ctop < window.innerHeight / 2 && cbottom > window.innerHeight / 5) {
      tab4 && tab4.classList.add("spintab");
    } else {
      tab4 && tab4.classList.remove("spintab");
    }

    if (experienceHeader && leftNav) {
      var header = experienceHeader.getBoundingClientRect();

      if (header.top < window.innerHeight / 2) {
        
        leftNav.classList.remove("hide-left-link");
        leftNav.classList.add("show-left-link");
      } else {
        leftNav.classList.remove("show-left-link");
        leftNav.classList.add("hide-left-link");
      }
    }

    elements.forEach((element) => {
        if (!element.classList.contains("show-compoment")) {
          element.classList.remove("targets-container");
          inView(element, (info) => {
            if (!element.classList.contains("show-compoment")) {
              element.classList.add("show-compoment");
              animate(
                info.target,
                { y: [50, 0] },
                { type: "spring" }
              );
              return ;
            }
          });
        }
     
    });
    targetsWidth.forEach((element) => {
      var bounding = element.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        if (!element.classList.contains("show-compoment-width")) {
          element.classList.remove("targets-container-width");
          element.classList.add("show-compoment-width");
        }
      } else {
        return;
      }
    });
  });

  targetsWidth.forEach((element) => {
    var bounding = element.getBoundingClientRect();
    if (
      bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      if (!element.classList.contains("show-compoment-width")) {
        element.classList.remove("targets-container-width");
        element.classList.add("show-compoment-width");
      }
    } else {
      return;
    }
  });
};

export const PhotoHoverEffect = () => {
  const target = document.querySelector(".p_front");
  const frame = document.querySelector(".profile-picture_frame");
  target?.addEventListener("mouseover", () => {
    frame?.classList.add("hide-frame");
  });
  target?.addEventListener("mouseout", () => {
    frame?.classList.remove("hide-frame");
  });
};
