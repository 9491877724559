import { LabelProps } from "../utils/types";
import { Typography } from "@mui/material";
import React from "react";



export const Text:React.FC<LabelProps>=({className,styles,children,variant="subtitle1",...props})=>{
    return (
        <Typography 

                variant={variant}
        style={styles}
        className={className}
        sx={(theme)=>({
            color:theme.palette.mode==="dark" ?
             theme.palette.grey[100] :theme.palette.grey[800]
        })}
        {...props}
        >{children}</Typography>
    )
}


